import { Suspense, lazy } from 'react';
import './styles/App.css';
import ThreeDLoader from './components/Generics/threeDLoader/ThreeDLoader';
import { Route, Routes, Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import OnboardingLayout from './layouts/OnboardingLayout';
import PublicRoutes from './services/PublicRoutes';
import PrivateRoutes from './services/PrivateRoutes';
import Unauthorized from './pages/Unauthorized';

const Overview = lazy(() => import('./pages/Overview'));
const SingleChallenge = lazy(() => import('./pages/challenges/SingleChallenge'));
const CommunityMembers = lazy(() => import('./pages/CommunityMembers'));
const Reviewers = lazy(() => import('./pages/Reviewers'));
const ChallengesView = lazy(() => import('./pages/challenges/Challenges'));
const ChallengeTeams = lazy(() => import('./pages/challenges/ChallengeTeams'));
const Review = lazy(() => import('./pages/Reviews/Review'));
const ChallengeReviewDetails = lazy(
  () => import('./pages/Reviews/ChallengeReviewDetails')
);
const ReviewHistory = lazy(
  () => import('./pages/ReviewHistory/ReviewHistoryPage')
);
const ChallengeReviewHistoryDetails = lazy(
  () => import('./pages/ReviewHistory/ChallengeReviewHistoryDetails')
);

const Onboarding = lazy(() => import('./pages/Auth/Onboarding'));
const ForgotPassword = lazy(() => import('./pages/Auth/ForgotPassword'));
const Login = lazy(() => import('./pages/Auth/Signin'));
const UpdatePassword = lazy(() => import('./pages/Auth/UpdatePassword'));
// const ErrorPage = lazy(() => import('./pages/NotFound'));

function App() {
  return (
    <Suspense fallback={<ThreeDLoader active />}>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path='/' element={<DashboardLayout />}>
          <Route path='/overview' element={<Overview />} />
          <Route path='/members' element={<CommunityMembers />} />
          <Route path='/review' element={<Review />} />
          <Route path='/challenges' element={<ChallengesView />} />
          <Route path='/challenge/:challengeId' element={<SingleChallenge />} />
          <Route path='/challenges-teams/:challengeId' element={<ChallengeTeams />} />
          <Route path='/history' element={<ReviewHistory />} />
          <Route path="/reviewer" element={<Reviewers />} />
          <Route path='/reviews/:id' element={<ChallengeReviewDetails />} />
          <Route
            path='/history/:id'
            element={<ChallengeReviewHistoryDetails />}
          />
        </Route>
          <Route path='*' element={<Navigate to='/auth/login' replace />} />
          <Route path='/unauthorized' element={<Unauthorized />} />
        </Route>

        <Route element={<PublicRoutes />}>
          <Route path='/*' element={<OnboardingLayout />}>
            <Route path='onboarding/setup' element={<Onboarding />} />
            <Route path='auth/login' element={<Login />} />
            <Route path='auth/forgot-password' element={<ForgotPassword />} />
            <Route path='auth/update-password' element={<UpdatePassword />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
