export const useAuth = () => {
  //getting user from local storage
  const user = localStorage.getItem('talenvoUserProfile');

  const userData = JSON.parse(user);

  //checking if user role is a reviewer...
  if (userData?.user?.role === 'reviewer' || userData?.user?.role === 'partner-admin') {
    return true;
  } else {
    return false;
  }
};
