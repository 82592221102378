import {
  Link,
  NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AppLogo from "../../resources/logo.svg";
import BaseButton from "./BaseButton";
import Avatar from '../../resources/avatar.jpg';
import { MouseEvent, useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks/storeHook";
import { sidebarItem } from "../../types";
import { updateTokenStatus } from "../../store/mainReducer";
import SideNavItem from "./SideNavItem";
import SvgComponent from "./SvgComponent";
import useProfileData from "../../hooks/UserProfileData";
import ProfileCard from "../Users/ProfileCard";

interface SidebarProps {
  isOpen: boolean;
  hideSidebar: () => void;
}

const Sidebar = ({ isOpen, hideSidebar }: SidebarProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { firstName, lastName, jobTitle, profileImageUrl, partnerIds } = useProfileData();

  const currentPath = location.pathname;

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  const sidebarWidth = isOpen ? "w-64" : "hidden"; // Adjust the width as needed

  const [showProfileCard, setShowProfileCard] = useState(false);


  const handleProfileCard = () => {
    setShowProfileCard(true);
  };

  const handleCloseProfileCard = () => {
    setShowProfileCard(false);
  };

  const sidebarItems: sidebarItem[] = [
    {
      title: "Dashboard",
      icon: "dashboard-active",
      path: "/overview",
      disabled: false
    },
    {
      title: "Reviews",
      icon: "assessments-active",
      path: "/review?available=true&filter=",
      // path: "/review?filter=&submitted=true&available=true",
      disabled: false
    },
    {
      title: "Challenges",
      icon: "menu-board",
      path: "/challenges",
      disabled: false
    },
    {
      title: "Review history",
      icon: "assessments-history-active",
      path: "/history",
      disabled: false
    },
    {
      title: "Members",
      icon: "multiple-users",
      path: "/members",
      disabled: partnerIds && partnerIds.length > 0 ? false : true,
    },
    {
      title: "Reviewers",
      icon: "add-user-icon",
      path: "/reviewer",
      disabled: partnerIds && partnerIds.length > 0 ? false : true,
    },
  ];

  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1024);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isLargeScreen]);

  const handleLogout = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(updateTokenStatus(null));
    if (!isLargeScreen) {
      hideSidebar();
    }
    navigate("/auth/login");
  };

  const computeActiveState = (item: sidebarItem) => {
    if (currentPath === "/" && item.path === "/overview") {
      return true;
    }
    if (item.path.includes(currentPath) && location.search.startsWith('?filter=')) {
      return true;
    }
    return location.pathname.startsWith(item.path);
  };

  return (
    <nav
      id="side-navigation"
      className={`bg-white z-10 h-screen w-64 border-r-2 border-gray-200 pt-20 pb-10 fixed flex flex-col items-start justify-between no-scrollbar overflow-y-auto ease-in-out transition-all duration-300 ${sidebarWidth}`}
    >
      <div className="absolute z-10 top-5 left-9">
        <Link to="/overview">
          <img src={AppLogo} alt="Talenvo Logo" />
        </Link>
      </div>
      <div className="pl-2">
        {sidebarItems.map((item) => (
          <SideNavItem
            buttonId={item.title} 
            key={item.title}
            title={item.title}
            icon={item.icon}
            isDisabled={item.disabled}
            isActive={computeActiveState(item)}
            handleBtnClick={(e) => {
              if (!isLargeScreen) {
                hideSidebar();
              }
              navigate(item.path);
            }}
          />
        ))}
      </div>

      <div className="pl-8 py-15 w-full flex flex-col items-start justify-end gap-2">
        <div
          className="pl-3 mt-4 flex items-center gap-2 pb-6"
          onClick={handleProfileCard}
        >
          <div className="relative cursor-pointer">
            <img
              src={profileImageUrl || Avatar}
              alt="ProfilePicture"
              className="w-[50px] h-[50px] rounded-full "
              onError={(e) => {
                e.currentTarget.src = Avatar;
              }}
            />
            <div className="absolute bottom-0 left-9 w-2 h-2 rounded-full bg-[#10C181]"></div>
          </div>

          <div className="flex flex-col gap-1 w-[150px]">
            <h1 className="text-[14px] text-[#333] font-medium ">
             { (firstName || lastName) ?`${firstName ?? " "} ${lastName ?? ""}`.trim() : ""}
             
            </h1>
            <p className="text-[13px] text-[#666666] ">{jobTitle?.name}</p>
          </div>
        </div>

        {showProfileCard && <ProfileCard onClose={handleCloseProfileCard} />}

        <div className="pl-3 flex w-full ">
          <BaseButton
            buttonId={"logout-icon"}
            size="small"
            handleEngagement={(e) => handleLogout(e)}
          >
            <div className="flex items-center gap-4 justify-center">
              <span>
                <SvgComponent icon="logout-icon" />
              </span>
              <p className="text-small">Logout</p>
            </div>
          </BaseButton>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
